<template>
  <Modal v-model="showWindow" width="400" title="编辑商品" footer-hide>
    <Form ref="formValidate" :model="formValidate" :rules="ruleValidate" :label-width="80">
      <FormItem label="商品名称" prop="name">
          <Input v-model="formValidate.name" placeholder="请输入商品名称"></Input>
      </FormItem>
      <FormItem label="商品等级" prop="level" class="m-t-10">
          <RadioGroup v-model="formValidate.level">
              <Radio v-for="level in levelArray" :key="level.id" :label="level.id">{{level.name}}</Radio>
          </RadioGroup>
      </FormItem>
      <FormItem label="一级类型" prop="resourceBigtypeId">
        <Select v-model="formValidate.resourceBigtypeId" placeholder="请选择一级类型"
          @on-change="handleChangeResourceType">
          <Option v-for="item in resourceTypeArray" :value="item.id" :key="'resType_' + item.id">{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem label="二级类型" prop="resourcetypeId">
        <Select v-model="formValidate.resourcetypeId" filterable placeholder="请选择二级类型（可通过关键字搜索）">
          <Option v-for="item in resChildTypeArray" :value="item.id" :key="'resChildType_' + item.id">{{ item.name }}
          </Option>
        </Select>
      </FormItem>
      <FormItem class="m-t-10">
          <Button type="primary" @click="handleSubmit('formValidate')">提交</Button>
          <Button @click="handleReset('formValidate')" class="m-l-10 btn-black">重置</Button>
      </FormItem>
    </Form>
  </Modal>
</template>

<script>
import { getResourceTypeList } from '@/api/product/asset'
import { addInventory } from '@/api/product/cloudinventory2'

export default {
  data () {
    return {
      showWindow: false,
      publisherId: this.$store.getters.token.userInfo.publisherId,
      resourceTypeArray: [],
      resChildTypeArray: [], // 二级类别
      levelArray: [
        { id: 1, name: 'A' },
        { id: 2, name: 'A+' },
        { id: 3, name: 'A++' },
        { id: 4, name: 'S' },
        { id: 5, name: 'S+' },
        { id: 6, name: 'S++' }
      ],

      formValidate: {
        level: 1,
        name: '',
        resourceBigtypeId: null,
        resourcetypeId: null
      },
      ruleValidate: {
        name: [
          { required: true, message: '商品名称不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  mounted () {
    getResourceTypeList().then(res => {
      this.resourceTypeArray = res
      if (res.length > 0) {
        this.formValidate.resourceBigtypeId = res[0].id
        this.handleChangeResourceType()
      }
    })
  },
  methods: {
    showModal (resourceBean) {
      this.showWindow = true
    },
    handleChangeResourceType () {
      this.resChildTypeArray = this.resourceTypeArray.find(x => x.id === this.formValidate.resourceBigtypeId).children || []
      if (this.resChildTypeArray.length > 0) {
        this.formValidate.resourcetypeId = this.resChildTypeArray[0].id
      }
    },
    handleSubmit (name) {
      this.$refs[name].validate((valid) => {
        if (valid) {
          const addBean = {
            level: this.formValidate.level,
            name: this.formValidate.name,
            resourceType: this.formValidate.resourcetypeId,
            publisherId: this.publisherId
          }

          addInventory(addBean).then(res => {
            this.$Notice.success({ desc: '添加商品成功！' })
            this.showWindow = false
            this.$emit('on-success')
          })
        }
      })
    },
    handleReset (name) {
      this.formValidate.level = 1
      this.formValidate.name = ''
      this.formValidate.resourceBigtypeId = this.resourceTypeArray[0].id
      this.handleChangeResourceType()
    }
  }
}
</script>
