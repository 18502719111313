import request from '@/utils/requestV2'
const qs = require('qs')

// 媒体商城获取商品列表
export function getInventory (data) {
  return request.post('/ooh-product/v2/cloudinventory/getInventory', qs.stringify(data))
}
// 媒体商城添加商品
export function addInventory (data) {
  return request.post('/ooh-product/v2/cloudinventory/addInventory', qs.stringify(data))
}
// 媒体商城编辑商品
export function editInventory (data) {
  return request.post('/ooh-product/v2/cloudinventory/editInventory', qs.stringify(data))
}
// 媒体商城编辑资源
export function editResource (params) {
  return request.get('/ooh-product/v2/cloudinventory/editResource', { params })
}
// 媒体商城新增商品规格
export function addSchedule (data) {
  return request.post('/ooh-product/v2/cloudinventory/addSchedule', qs.stringify(data))
}
// 媒体商城编辑商品规格
export function editSchedule (data) {
  return request.post('/ooh-product/v2/cloudinventory/editSchedule', qs.stringify(data))
}
// 媒体商城获取商品规格
export function getSchedules (params) {
  return request.get('/ooh-product/v2/cloudinventory/getSchedules', { params })
}
